<template>
  <v-row dense class="d-flex fill-height">
    <v-col class="d-flex justify-center flex-fill fill-height">
      <v-card width="50%" class="mt-10">
        <v-card-title>비밀번호 변경</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col><v-text-field type="password" v-model="pwd.current" label="현재 비밀번호" /></v-col>
          </v-row>
          <v-row dense class="mt-4">
            <v-col><v-text-field type="password" v-model="pwd.change" label="신규 비밀번호" /></v-col>
          </v-row>
          <v-row dense>
            <v-col><v-text-field type="password" v-model="pwd.confirm" label="비밀번호 확인" /></v-col>
          </v-row>
          <v-row dense>
            <v-col><v-btn color="info" :disabled="!pwd.current || !pwd.change || pwd.change != pwd.confirm" @click.stop="changePassword">비밀번호 변경</v-btn></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import api from '@/services/api'
import notify from '@/utils/notify'
export default {
  name: 'ResetPassword',
  metaInfo: {
    title: '비밀번호 변경'
  },
  data() {
    return {
      pwd: {}
    }
  },
  methods: {
    changePassword() {
      api.passwordChange(this.pwd.current, this.pwd.change, this.pwd.confirm).then(() => {
        notify.showMessage('변경 되었습니다.')
      })
    }
  },
  created() {
    this.$store.commit('setAppBarTitle', '비밀번호 변경')
  }
}
</script>
